import { createRouter, createWebHistory, RouterView } from "vue-router";
import useAuth from "@/providers/auth";

const routes = [
  {
    path: "/",
    name: "Index",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/system",
    name: "Sistema",
    component: () => import("@/views/system/Index.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        meta: {
          icon: "fas fa-home",
          roles: [],
          menu: true,
          deep: false,
        },
        component: () => import("@/views/system/Dashboard.vue"),
      },
      {
        path: "admin",
        name: "Administración",
        meta: {
          icon: "fas fa-user",
          roles: [],
          menu: true,
          deep: false,
        },
        component: RouterView,
        children: [
          {
            path: "",
            component: () =>
              import("@/views/system/administrators/administrators.vue"),
            name: "Administradores",
          },
          {
            path: "new",
            component: () =>
              import("@/views/system/administrators/administrator.vue"),
            name: "Nuevo Administrador",
          },
          {
            path: "edit/:id_user",
            component: () =>
              import("@/views/system/administrators/administrator.vue"),
            name: "Editar Administrador",
          },
        ],
      },
      {
        path: "company",
        name: "Empresa",
        meta: {
          icon: "fas fa-building",
          roles: ["coordinador_ccrh"],
          menu: true,
          deep: true,
        },
        component: RouterView,
        children: [
          {
            meta: {
              menu: true,
            },
            path: "",
            name: "Departamentos",
            component: () => import("@/views/system/company/departments.vue"),
          },
          {
            meta: {
              menu: true,
            },
            path: "employees",
            name: "Empleados",
            component: () => import("@/views/system/company/employees.vue"),
          },
          {
            meta: {
              menu: false,
            },
            path: "employee",
            name: "Nuevo Empleado",
            component: () => import("@/views/system/company/employee.vue"),
          },
          {
            meta: {
              menu: false,
            },
            path: "employee/:id",
            name: "Editar Empleado",
            component: () => import("@/views/system/company/employee.vue"),
          },
        ],
      },
      {
        path: "price-list",
        name: "Lista de precios",
        meta: {
          icon: "fas fa-money-check-alt",
          roles: [],
          menu: true,
          deep: false,
        },
        component: RouterView,
        children: [
          {
            meta: {
              menu: true,
            },
            path: "",
            component: () => import("@/views/system/price_list/price_list.vue"),
            name: "Listas de precios",
          },
          {
            meta: {
              menu: true,
            },
            path: "new",
            component: () => import("@/views/system/price_list/list.vue"),
            name: "Nueva Lista de Precios",
          },
          {
            meta: {
              menu: true,
            },
            path: "edit/:id",
            component: () => import("@/views/system/price_list/list.vue"),
            name: "Editar Lista de precios",
          },
        ],
      },
      {
        path: "inventory",
        name: "Inventario",
        meta: {
          icon: "fas fa-clipboard-list",
          roles: ["coordinador_lapt", "compras", "produccion", "gerente"],
          menu: true,
          deep: false,
        },
        component: () => import("@/views/system/warehouse/inventory/index.vue"),
      },
      {
        path: "customers",
        name: "Clientes",
        meta: {
          icon: "fas fa-users",
          roles: ["coordinador_vp"],
          menu: true,
          deep: false,
        },
        component: RouterView,
        children: [
          {
            meta: {
              menu: true,
            },
            path: "",
            component: () => import("@/views/system/customers/customers.vue"),
            name: "Clientes ",
          },
          {
            meta: {
              menu: true,
            },
            path: "new",
            component: () => import("@/views/system/customers/customer.vue"),
            name: "Nuevo cliente",
          },
          {
            meta: {
              menu: true,
            },
            path: "edit/:id",
            component: () => import("@/views/system/customers/customer.vue"),
            name: "Editar cliente",
          },
        ],
      },
      {
        path: "supliers",
        name: "Proveedores",
        meta: {
          icon: "fas fa-truck-loading",
          roles: ["compras", "gerente"],
          menu: true,
          deep: true,
        },
        component: RouterView,
        children: [
          {
            meta: {
              menu: true,
            },
            path: "",
            component: () =>
              import("@/views/system/supliers/supliers/supliers.vue"),
            name: "Proveedores ",
          },
          {
            meta: {
              menu: false,
            },
            path: "new",
            component: () =>
              import("@/views/system/supliers/supliers/suplier.vue"),
            name: "Nuevo Proveedor",
          },
          {
            meta: {
              menu: false,
            },
            path: "edit/:id",
            component: () =>
              import("@/views/system/supliers/supliers/suplier.vue"),
            name: "Editar Proveedor",
          },
          {
            path: "supplies",
            name: "Insumos",
            meta: {
              menu: true,
              deep: false,
            },
            component: RouterView,
            children: [
              {
                meta: {
                  menu: true,
                },
                path: "",
                name: "Lista Insumos",
                component: () =>
                  import("@/views/system/supliers/supplies/supplies.vue"),
              },
              {
                meta: {
                  menu: true,
                },
                path: "new",
                name: "Nuevo Insumos",
                component: () =>
                  import("@/views/system/supliers/supplies/supplie.vue"),
              },
              {
                meta: {
                  menu: true,
                },
                path: "edit/:id",
                name: "Editar Insumos",
                component: () =>
                  import("@/views/system/supliers/supplies/supplie.vue"),
              },
            ],
          },
          {
            path: "materia",
            name: "Materia Prima",
            meta: {
              menu: true,
              deep: false,
            },
            component: RouterView,
            children: [
              {
                meta: {
                  menu: true,
                },
                path: "",
                name: "Lista Materia",
                component: () =>
                  import("@/views/system/supliers/materia/materias.vue"),
              },
              {
                meta: {
                  menu: true,
                },
                path: "new",
                name: "Nuevo Materia",
                component: () =>
                  import("@/views/system/supliers/materia/materia.vue"),
              },
              {
                meta: {
                  menu: true,
                },
                path: "edit/:id",
                name: "Editar Materia",
                component: () =>
                  import("@/views/system/supliers/materia/materia.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "purchases",
        name: "Compras",
        meta: {
          icon: "fas fa-shopping-basket",
          roles: [
            "coordinador_vp",
            "coordinador_ccrh",
            "compras",
            "coordinador_lapt",
            "calidad",
            "produccion",
            "gerente",
          ],
          menu: true,
          deep: true,
        },
        component: RouterView,
        children: [
          {
            meta: {
              roles: [
                "coordinador_vp",
                "coordinador_ccrh",
                "compras",
                "coordinador_lapt",
                "calidad",
                "produccion",
                "gerente",
              ],
              menu: true,
            },
            path: "",
            component: () =>
              import("@/views/system/purchases/requisitions/requisitions.vue"),
            name: "Requisiciones",
          },
          {
            meta: {
              roles: [
                "coordinador_vp",
                "coordinador_ccrh",
                "compras",
                "coordinador_lapt",
                "calidad",
                "produccion",
                "gerente",
              ],
              menu: false,
            },
            path: "requisition",
            component: () =>
              import("@/views/system/purchases/requisitions/requisition.vue"),
            name: "Nueva requisición",
          },
          {
            meta: {
              roles: ["admin", "compras", "gerente"],
              menu: false,
            },
            path: "requisition/:id",
            component: () =>
              import("@/views/system/purchases/requisitions/requisition.vue"),
            name: "Editar requisición",
          },
          {
            meta: {
              roles: ["admin", "compras", "gerente"],
              menu: true,
            },
            path: "orders",
            component: () =>
              import("@/views/system/purchases/orders/orders.vue"),
            name: "Ordenes de Compra",
          },
          {
            meta: {
              roles: ["compras", "gerente"],
              menu: true,
            },
            path: "payments",
            component: () =>
              import("@/views/system/purchases/payments/orders.vue"),
            name: "Pagos compras",
          },
          {
            meta: {
              roles: ["admin", "compras", "gerente"],
              menu: true,
            },
            path: "reception",
            component: () =>
              import("@/views/system/purchases/reception/orders.vue"),
            name: "Recepción de compras",
          },
        ],
      },
      {
        path: "production",
        name: "Producción",
        meta: {
          icon: "fas fa-hammer",
          roles: ["produccion", "gerente"],
          menu: true,
          deep: false,
        },
        component: RouterView,
        children: [
          {
            meta: {
              menu: true,
            },
            path: "",
            component: () => import("@/views/system/production/list.vue"),
            name: "Lista Producción",
          },
          {
            meta: {
              menu: true,
            },
            path: "new",
            component: () => import("@/views/system/production/production.vue"),
            name: "Nueva producción",
          },
          {
            meta: {
              menu: true,
            },
            path: "edit/:id",
            component: () => import("@/views/system/production/production.vue"),
            name: "Editar producción",
          },
        ],
      },
      {
        path: "warehouse",
        name: "Almacen",
        meta: {
          icon: "fas fa-warehouse",
          roles: ["coordinador_lapt", "operador", "compras", "gerente"],
          menu: true,
          deep: true,
        },
        component: RouterView,
        children: [
          {
            meta: {
              roles: ["coordinador_lapt", "operador", "gerente"],
              menu: true,
            },
            path: "sales",
            component: RouterView,
            name: "Solicitudes de Envío",
            children: [
              {
                meta: {
                  menu: true,
                },
                path: "",
                component: () =>
                  import("@/views/system/warehouse/sales/sales.vue"),
                name: "Logistica - Lista ventas",
              },
              {
                meta: {
                  menu: false,
                },
                path: "delivery/:id",
                component: () =>
                  import("@/views/system/warehouse/sales/delivery.vue"),
                name: "Logistica - Envio de venta",
              },
              {
                meta: {
                  menu: false,
                },
                path: "load/:id",
                component: () =>
                  import("@/views/system/warehouse/sales/load.vue"),
                name: "Logistica - Carga de producto",
              },
              {
                meta: {
                  menu: false,
                },
                path: "details/:id",
                component: () =>
                  import("@/views/system/warehouse/sales/detail.vue"),
                name: "Logistica - Detalles de envio",
              },
            ],
          },
          {
            meta: {
              menu: true,
              roles: ["compras", "gerente"],
            },
            path: "production/",
            name: "Solicitudes Producción",
            component: RouterView,
            children: [
              {
                meta: {
                  menu: true,
                },
                path: "",
                component: () =>
                  import("@/views/system/warehouse/production/requests.vue"),
                name: "Solicitudes Insumos",
              },
              {
                meta: {
                  menu: true,
                },
                path: "deliver/:id",
                component: () =>
                  import("@/views/system/warehouse/production/deliver.vue"),
                name: "Entrega Insumos",
              },
              {
                meta: {
                  menu: true,
                },
                path: "return/:id",
                component: () =>
                  import("@/views/system/warehouse/production/return.vue"),
                name: "Regresar Insumos",
              },
              {
                meta: {
                  menu: true,
                },
                path: "return_finished/:id",
                component: () =>
                    import("@/views/system/warehouse/production/return_finished.vue"),
                name: "Regresar Insumos Terminado",
              }
            ],
          },
        ],
      },
      {
        path: "faults",
        name: "Defectos",
        meta: {
          icon: "fas fa-check",
          roles: ["calidad"],
          menu: true,
          deep: false,
        },
        component: RouterView,
        children: [
          {
            meta: {
              menu: true,
            },
            path: "",
            component: () => import("@/views/system/faults/faults.vue"),
            name: "Defectos ",
          },
          {
            meta: {
              menu: true,
            },
            path: "new",
            component: () => import("@/views/system/faults/fault.vue"),
            name: "Nuevo Defecto",
          },
          {
            meta: {
              menu: true,
            },
            path: "edit/:id",
            component: () => import("@/views/system/faults/fault.vue"),
            name: "Editar Defecto",
          },
        ],
      },
      {
        path: "my-sales",
        name: "Pedidos",
        meta: {
          icon: "fas fa-cash-register",
          roles: ["vendedor", "coordinador_vp"],
          deep: true,
          menu: true,
        },
        component: RouterView,
        children: [
          {
            meta: {
              menu: true,
            },
            path: "",
            component: () => import("@/views/system/my-sales/sales.vue"),
            name: "Pedidos ",
          },
          {
            meta: {
              menu: true,
            },
            path: "new",
            component: () => import("@/views/system/my-sales/sale.vue"),
            name: "Nuevo Pedido",
          },
        ],
      },
      {
        path: "sales",
        name: "Ventas",
        meta: {
          icon: "fas fa-money-bill-wave-alt",
          roles: ["coordinador_vp", "coordinador_lapt", "coordinador_ccrh", "auxiliar_ccrh", "gerente"],
          menu: true,
          deep: true,
        },
        component: RouterView,
        children: [
          {
            meta: {
              roles: ["coordinador_lapt", "gerente"],
              menu: true,
            },
            path: "",
            component: () => import("@/views/system/sales/sales/sales.vue"),
            name: "Órdenes de venta",
          },
          {
            meta: {
              roles: ["coordinador_lapt"],
              menu: false,
            },
            path: "delivery/:id",
            component: () => import("@/views/system/sales/sales/delivery.vue"),
            name: "Envio de venta",
          },
          {
            meta: {
              roles: ["coordinador_vp", "coordinador_ccrh", "auxiliar_ccrh"],
              menu: true,
            },
            path: "billing",
            component: RouterView,
            name: "Facturacion",
            children: [
              {
                meta: {
                  menu: true,
                },
                path: "",
                component: () =>
                  import("@/views/system/sales/billing/sales.vue"),
                name: "Facturacion ",
              },
              {
                meta: {
                  menu: true,
                },
                path: "document/:id",
                component: () =>
                  import("@/views/system/sales/billing/document.vue"),
                name: "Nuevo documento",
              },
              {
                meta: {
                  roles: ["coordinador_vp"],
                  menu: false,
                },
                path: "expenses",
                component: RouterView,
                name: "Gastos",
                children: [
                  {
                    meta: {
                      menu: true,
                    },
                    path: "",
                    component: () =>
                      import("@/views/system/sales/expenses/expenses.vue"),
                    name: "Gastos ",
                  },
                  {
                    meta: {
                      menu: true,
                    },
                    path: "new/",
                    component: () =>
                      import("@/views/system/sales/expenses/expense.vue"),
                    name: "Nuevo Gasto",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        meta: {
          icon: "fas fa-file",
          roles: ["admin", "coordinador_vp", "coordinador_lapt", "produccion", "calidad", "developer","vendedor", "gerente"],
          deep: true,
          menu: true,
        },
        path: "reports",
        component: RouterView,
        name: "Reportes",
        children: [
          {
            meta: {
              menu: true,
              roles: ["coordinador_vp", "coordinador_lapt", "gerente"],
            },
            path: "sales",
            component: () => import("@/views/system/reports/sales.vue"),
            name: "R. Pedidos",
          },
          {
            meta: {
              menu: true,
              roles: ["admin", "produccion", "calidad", "gerente"],
            },
            path: "production",
            component: () => import("@/views/system/reports/production.vue"),
            name: "R. Producción",
          },
          {
            meta: {
              menu: true,
              roles: ["admin", "calidad", "gerente"],
            },
            path: "serials_report",
            component: () => import("@/views/system/reports/serials_report.vue"),
            name: "R. Seriales",
          },
          {
            meta: {
              menu: true,
              roles: ["admin","vendedor","coordinador_vp"],
            },
            path: "sales_report",
            component: () => import("@/views/system/reports/sales_report.vue"),
            name: "R. Ventas",
          },
        ],
      },
      // {
      //   meta: {
      //     icon: "fas fa-file",
      //     roles: ["developer"],
      //     deep: true,
      //     menu: true,
      //   },
      //   path: "others",
      //   component: RouterView,
      //   name: "Otros",
      //   children: [
      //     {
      //       meta: {
      //         menu: true,
      //         roles: ["developer"],
      //       },
      //       path: "change_pdf_labels",
      //       component: () => import("@/views/system/others/change_pdf_labels.vue"),
      //       name: "Actualización etiquetas",
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/P404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const { user, token } = useAuth();

router.beforeEach((to, from, next) => {
  if (to.meta.auth && (!user.value || token.value === undefined)) {
    next({ name: "Login", replace: true });
  } else if (to.name === "Login" && user.value) {
    switch (user.value.role) {
      case "":
        break;
      default:
        next({ name: "Dashboard", replace: true });
        break;
    }
  } else {
    if (to.meta.roles) {
      if (user.value.role) {
        next();
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
